import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Box, Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Usage`}</h2>
    <p>{`Use state label to show the status of an issue or pull request.`}</p>
    <h2>{`Anatomy`}</h2>
    <img width="960" alt="State label showing an open and merged label" src="https://github.com/primer/design/assets/586552/00cadd5f-67df-491a-b4a4-629a25a10188" />
    <h3>{`Types and states`}</h3>
    <p>{`The State label variant can be used for issues, which uses the issues icon, or pull requests, which uses the pull request icon. `}</p>
    <p>{`Status for the State label can be one of the following options: draft (gray), open (green), closed (purple for issues and red for pull requests), merged (purple, used for pull requests
only), or unavailable (gray, used for `}<a parentName="p" {...{
        "href": "/ui-patterns/degraded-experiences"
      }}>{`degraded experiences`}</a>{`).`}</p>
    <img role="presentation" alt="State label displayed for issues and pull requests" src="https://github.com/primer/design/assets/2313998/ee2a086b-614f-487f-b4dd-a95e1c954d40" />
    <img role="presentation" alt="State labels displayed in all status variations, including draft, open, closed, and merged." src="https://github.com/primer/design/assets/2313998/cdd29243-22d8-4131-87aa-4148dd9c446d" />
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="StateLabel" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      